import { graphql } from "gatsby"
import { Insights } from "../Insights/Insights"

export const query = graphql`
  query InsightsQuery {
    insights: markdownRemark(
      fileAbsolutePath: { regex: "/netlify/insights.md/" }
    ) {
      frontmatter {
        title
        featuredBlogPost
        reports {
          title
          featuredReports
          link
          buttonText
        }
        newsletter {
          sideImage {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
    featuredInsights: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/netlify/blog/" } }
    ) {
      nodes {
        fileAbsolutePath
        frontmatter {
          title
          category
          summary
          featuredImage {
            childImageSharp {
              gatsbyImageData
            }
          }
          author {
            name
            link
          }
        }
      }
    }
    site {
      ...SiteFragment
    }
    header: markdownRemark(fileAbsolutePath: { regex: "/netlify/header.md/" }) {
      ...HeaderFragment
    }
    footer: markdownRemark(fileAbsolutePath: { regex: "/netlify/footer.md/" }) {
      ...FooterFragment
    }
  }
`

export default Insights
