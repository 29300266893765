import styled from "@emotion/styled"
import React from "react"
import { IGatsbyImageData, GatsbyImage } from "gatsby-plugin-image"
import { breakpoint } from "../../theme/theme"
import { PlainLink } from "../PlainLink/PlainLink"
import { Typography } from "../Typography/Typography"

interface ExtendedBlogPostProps {
  title: string
  description: string
  link: string
  image: IGatsbyImageData
}
export const ExtendedBlogPost: React.FC<ExtendedBlogPostProps> = ({
  title,
  description,
  link,
  image,
}) => {
  return (
    <PlainLink to={link}>
      <Card>
        <TextContainer>
          <Title variant="h5">{title}</Title>
          <Text variant="p">{description}</Text>
        </TextContainer>
        <ImageContainer>
          <StyledImage image={image} alt={title} />
        </ImageContainer>
      </Card>
    </PlainLink>
  )
}

const Card = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "3fr 1fr",
  boxShadow: theme.boxShadow.light,
  borderRadius: "8px",
  [breakpoint("sm")]: {
    gridTemplateColumns: "2fr 1fr",
  },
}))

const TextContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "1em",
  color: theme.palette.darkblue.main,
  padding: "10px",
  [breakpoint("sm")]: {
    padding: "32px",
  },
}))

const ImageContainer = styled("div")({
  padding: "10px 10px 0 0",
  [breakpoint("sm")]: {
    padding: "0",
  },
})
const StyledImage = styled(GatsbyImage)({
  width: "100%",
  height: "60%",
  [breakpoint("sm")]: {
    width: "100%",
    height: "100%",
  },
})

const Title = styled(Typography)({
  fontSize: "16px",
  [breakpoint("sm")]: {
    fontSize: "20px",
  },
})

const Text = styled(Typography)({
  fontSize: "12px",
  [breakpoint("sm")]: {
    fontSize: "16px",
  },
})
