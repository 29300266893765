import styled from "@emotion/styled"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import React from "react"
import { breakpoint } from "../../theme/theme"
import { getSlugFromTitle } from "../../utils/slug"
import { AccentDivider } from "../AccentDivider/AccentDivider"
import { Paper } from "../Paper/Paper"
import { PlainLink } from "../PlainLink/PlainLink"
import { Typography } from "../Typography/Typography"

interface FeaturedBlogPostProps {
  category: string
  title: string
  description: string
  coverImage: IGatsbyImageData
  author: {
    name: string
    link: string
  }
}
export const FeaturedBlogPost: React.FC<FeaturedBlogPostProps> = ({
  category,
  title,
  description,
  coverImage,
  author,
}) => {
  const link = getSlugFromTitle(title)
  return (
    <PlainLink to={`/insights/${link}`}>
      <StyledPaper color="blue" gradient>
        <FeaturedImage image={coverImage} alt={title} />
        <ContentContainer>
          <CategoryContainer>
            <Category variant="caption">{category}</Category>
            <AccentDivider />
          </CategoryContainer>
          <Title variant="h3">{title}</Title>
          <Description variant="p">{description}</Description>
          <CategoryContainer>
            <UnstyledLink href={author.link} target="_blank">
              <AuthorName variant="p">{author.name}</AuthorName>
            </UnstyledLink>
          </CategoryContainer>
        </ContentContainer>
      </StyledPaper>
    </PlainLink>
  )
}

const StyledPaper = styled(Paper)({
  color: "#ffffff",
  display: "grid",
  [breakpoint("md")]: {
    gridTemplateColumns: "1fr 1fr",
  },
  borderRadius: "20px",
})

const FeaturedImage = styled(GatsbyImage)({
  height: "100%",
  width: "100%",
  clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 98%)",
  borderRadius: "20px 20px 0 0",
  [breakpoint("md")]: {
    clipPath: "polygon(0 0, 100% 0, 95% 100%, 0% 100%)",
    borderRadius: "20px 0 0 20px",
  },
})

const ContentContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "0.5em",
  padding: "25px 15px",
  [breakpoint("sm")]: {
    gap: "2em",
    padding: "50px 25px",
  },
})

const CategoryContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  gap: "1em",
})

const Category = styled(Typography)({
  fontWeight: 800,
  letterSpacing: "0.32em",
  textTransform: "uppercase",
})

const Title = styled(Typography)({
  lineHeight: "32px",
  fontSize: "24px",
  [breakpoint("sm")]: {
    fontSize: "32px",
    lineHeight: "48px",
  },
})

const AuthorName = styled(Typography)({
  fontWeight: 700,
})

const Description = styled(Typography)({
  lineHeight: "24px",
})

const UnstyledLink = styled("a")({
  textDecoration: "none",
  color: "#fff",
})
