import { getImage, IGatsbyImageData, ImageDataLike } from "gatsby-plugin-image"

export const getFeaturedBlogsWithImages = (
  featuredBlogs: Array<{
    title: string
    category: string
    summary: string
    featuredImage: ImageDataLike
    author: {
      name: string
      link: string
    }
  }>
) => {
  return featuredBlogs.map(({ featuredImage, ...others }) => {
    const image = process.env.STORYBOOK
      ? (featuredImage as IGatsbyImageData)
      : getImage(featuredImage)
    if (!image) {
      throw new Error(`Could not find image for ${others.title}`)
    }

    return { ...others, featuredImage: image }
  })
}

export const getNewsletterImage = (image: ImageDataLike) => {
  const imageData = process.env.STORYBOOK
    ? (image as IGatsbyImageData)
    : getImage(image)
  if (!imageData) {
    throw new Error("Could not find image for newsletter card")
  }

  return imageData
}
