import styled from "@emotion/styled"
import { PageProps } from "gatsby"
import { ImageDataLike } from "gatsby-plugin-image"
import React from "react"
import { Button } from "../components/Button/Button"
import { ExtendedBlogPost } from "../components/ExtendedBlogPost/ExtendedBlogPost"
import { FeaturedBlogPost } from "../components/FeaturedBlogPost/FeaturedBlogPost"
import { InsightsMenu } from "../components/InsightsMenu/InsightsMenu"
import { BasePageData, withLayout } from "../components/Layout/layout.hocs"
import { NewsletterCard } from "../components/NewsletterCard/NewsletterCard"
import { PlainLink } from "../components/PlainLink/PlainLink"
import { Typography } from "../components/Typography/Typography"
import { breakpoint } from "../theme/theme"
import { getFilteredFeatures, getSlugFromTitle } from "../utils/slug"
import {
  getFeaturedBlogsWithImages,
  getNewsletterImage,
} from "./insights.utils"

export interface InsightsData extends BasePageData {
  insights: {
    frontmatter: {
      title: string
      featuredBlogPost: string
      reports: {
        title: string
        featuredReports: Array<string>
        link: string
        buttonText: string
      }
      newsletter: {
        sideImage: ImageDataLike
      }
    }
  }
  featuredInsights: {
    nodes: Array<{
      fileAbsolutePath: string
      frontmatter: {
        title: string
        category: string
        summary: string
        featuredImage: ImageDataLike
        author: {
          name: string
          link: string
        }
      }
    }>
  }
}

type InsightsProps = PageProps<InsightsData>

export const Insights: React.FC<InsightsProps> = withLayout(
  { title: "Insights", variant: "light" },
  (props) => {
    const { insights, featuredInsights } = props.data
    const featuredBlogPost = getFeaturedBlogsWithImages(
      getFilteredFeatures(
        [insights.frontmatter.featuredBlogPost],
        featuredInsights.nodes
      )
    )[0]
    const featuredReports = getFeaturedBlogsWithImages(
      getFilteredFeatures(
        insights.frontmatter.reports.featuredReports,
        featuredInsights.nodes
      )
    )

    const { title, reports, newsletter } = insights.frontmatter
    const newsletterImage = getNewsletterImage(newsletter.sideImage)

    return (
      <>
        <MaxWidthContainer>
          <InsightsMenu />
        </MaxWidthContainer>
        <MainContainer>
          <PageTitle variant="h1">{title}</PageTitle>
          <FeaturedBlogPost
            title={featuredBlogPost.title}
            category={featuredBlogPost.category}
            description={featuredBlogPost.summary}
            author={featuredBlogPost.author}
            coverImage={featuredBlogPost.featuredImage}
          />
        </MainContainer>
        <ReportsSection>
          <Typography variant="h3">{reports.title}</Typography>
          <ReportsContainer>
            {featuredReports.map(({ title, summary, featuredImage }) => {
              const link = getSlugFromTitle(title)
              return (
                <ExtendedBlogPost
                  title={title}
                  description={summary}
                  image={featuredImage}
                  link={`/insights/${link}`}
                  key={title}
                />
              )
            })}
            <ReportsButton>
              <PlainLink to={`/insights/${reports.link}`}>
                <ViewMoreButton variant="outlined">
                  {reports.buttonText}
                </ViewMoreButton>
              </PlainLink>
            </ReportsButton>
          </ReportsContainer>
        </ReportsSection>
        <NewsletterContainer>
          <NewsletterCard sideImage={newsletterImage} />
        </NewsletterContainer>
      </>
    )
  }
)

const MaxWidthContainer = styled("div")({
  margin: "0 auto",
  width: "auto",
  padding: "0 2em",
  [breakpoint("lg")]: {
    maxWidth: "1200px",
  },
})

const MainContainer = styled(MaxWidthContainer)({
  padding: "4em 2em",
  display: "flex",
  flexDirection: "column",
  gap: "2em",
})

const ReportsSection = styled(MaxWidthContainer)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "4em",
  color: theme.palette.darkblue.main,
  padding: "4em 2em",
}))

const ReportsContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "2em",
})

const ReportsButton = styled("div")({
  display: "flex",
  justifyContent: "center",
})

const NewsletterContainer = styled(MainContainer)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
})

const PageTitle = styled(Typography)(({ theme }) => ({
  fontSize: "32px",
  color: theme.palette.darkblue.main,
  [breakpoint("md")]: {
    fontSize: "48px",
  },
}))

const ViewMoreButton = styled(Button)(({ theme }) => ({
  color: theme.palette.darkblue.main,
  borderColor: theme.palette.darkblue.main,
}))
